

















































































































import Vue from 'vue'
import { mapGetters } from 'vuex'

// components
import SalesByPostedDateChart from '@/components/dashboard/charts/sales/partials/SalesByPostedDateChart.vue'
import CommissionQuotaAttainmentGroups from '@/components/dashboard/CommissionQuotaAttainmentGroups.vue'

export default Vue.extend({
  metaInfo: {
    title: 'Sales Charts - Dashboard',
  },
  components: {
    CommissionQuotaAttainmentGroups,
    SalesByPostedDateChart,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({
    compareToCommissionQuotaPeriod: undefined as number | undefined,
  }),
  computed: {
    ...mapGetters(['commissionQuotaPeriods']),
  },
  methods: {
    replaceProp<T>(obj: T, prop: keyof T, value: unknown): T {
      return {
        ...obj,
        [prop]: value,
      }
    },
  },
})
