var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('commission-quota-attainment-groups',{staticClass:"pt-16 pt-sm-0",attrs:{"period":_vm.period},scopedSlots:_vm._u([{key:"top-right",fn:function(){return [_c('v-select',{staticStyle:{"width":"128px"},attrs:{"solo":"","clearable":"","hide-details":"","label":"Compare","items":_vm.commissionQuotaPeriods,"item-text":"abbr","item-value":"id"},model:{value:(_vm.compareToCommissionQuotaPeriod),callback:function ($$v) {_vm.compareToCommissionQuotaPeriod=$$v},expression:"compareToCommissionQuotaPeriod"}})]},proxy:true},{key:"commission-component-group",fn:function(ref){
var group = ref.group;
var item = ref.item;
return [_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('sales-by-posted-date-chart',{attrs:{"start-date":group.commissionQuotaPeriod.startDate,"end-date":group.commissionQuotaPeriod.endDate,"commission-quota-amount":item.commissionQuotaAmount,"commission-quota-title":((group.commissionQuotaPeriod.abbr) + " Quota"),"height":"300px","group":{
          companyId: group.company.id,
          salesAreaId: group.salesArea.id,
          salespersonId: group.salesperson.id,
          commissionQuotaPeriodId: group.commissionQuotaPeriod.id,
          currency: group.currency,
          commissionComponentGroupId: item.commissionComponentGroup.id,
        },"series-title":((group.commissionQuotaPeriod.abbr) + " Sales"),"additional-series":_vm.compareToCommissionQuotaPeriod
            ? {
                name:
                  _vm.commissionQuotaPeriods.find(
                    function (z) { return z.id === _vm.compareToCommissionQuotaPeriod; }
                  ).abbr + ' Sales',
                group: _vm.replaceProp(
                  {
                    companyId: group.company.id,
                    salesAreaId: group.salesArea.id,
                    salespersonId: group.salesperson.id,
                    commissionQuotaPeriodId: group.commissionQuotaPeriod.id,
                    currency: group.currency,
                    commissionComponentGroupId:
                      item.commissionComponentGroup.id,
                  },
                  'commissionQuotaPeriodId',
                  _vm.compareToCommissionQuotaPeriod
                ),
              }
            : undefined,"title":(item.commissionComponentGroup.abbr + ' Total').toUpperCase()}})],1)]}},{key:"default",fn:function(ref){
            var item = ref.item;
return [_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('sales-by-posted-date-chart',{attrs:{"start-date":item.commissionQuotaPeriod.startDate,"end-date":item.commissionQuotaPeriod.endDate,"commission-quota-amount":item.commissionQuotaAmount,"commission-quota-title":((item.commissionQuotaPeriod.abbr) + " Quota"),"height":"300px","group":{
          companyId: item.company.id,
          salesAreaId: item.salesArea.id,
          salespersonId: item.salesperson.id,
          commissionQuotaPeriodId: item.commissionQuotaPeriod.id,
          currency: item.currency,
          commissionComponentId: item.commissionComponent.id,
        },"series-title":((item.commissionQuotaPeriod.abbr) + " Sales"),"additional-series":_vm.compareToCommissionQuotaPeriod
            ? {
                name:
                  _vm.commissionQuotaPeriods.find(
                    function (z) { return z.id === _vm.compareToCommissionQuotaPeriod; }
                  ).abbr + ' Sales',
                group: _vm.replaceProp(
                  {
                    companyId: item.company.id,
                    salesAreaId: item.salesArea.id,
                    salespersonId: item.salesperson.id,
                    commissionQuotaPeriodId: item.commissionQuotaPeriod.id,
                    currency: item.currency,
                    commissionComponentId: item.commissionComponent.id,
                  },
                  'commissionQuotaPeriodId',
                  _vm.compareToCommissionQuotaPeriod
                ),
              }
            : undefined,"title":(
            item.commissionComponent.abbr +
            ' / ' +
            item.commissionComponent.group.abbr
          ).toUpperCase()}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }