











































import Vue, { PropType } from 'vue'
import { ApexOptions } from 'apexcharts'
import deepFreeze from 'deep-freeze'

// components
import OrderDetailsCard from '@/components/orders/OrderDetailsCard.vue'
import { Query } from '@/components/charts/chart-settings'

// utils
import { padStart } from '@/utils/string-helpers'
import { currencyFormatter } from '@/utils/format-helpers'

// types
import { ChartCumulativeAmountByDateItem } from '@/types'

const addDays = (date: string, days: number) => {
  const [y, m, d] = date.split('-').map(n => Number(n))
  const result = new Date(Date.UTC(y, m - 1, d))
  result.setDate(result.getDate() + (days - 1))
  return result.toISOString().split('T')[0]
}

type Group = Query

type Data = {
  data: ReadonlyArray<unknown>
  additionalData: ReadonlyArray<unknown>
}

type QuotaData = {
  endDate: string
  amount?: number
}

type DateField = 'postedDate'
type AmountField = 'postedAmount'

type AdditionalDatum = ChartCumulativeAmountByDateItem & {
  commissionQuotaPeriodPostedDay: number
}

export default Vue.extend({
  components: { OrderDetailsCard },
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },

    additionalSeries: {
      type: Object as PropType<undefined | { group: Group; name?: string }>,
      default: undefined,
    },

    startDate: String,
    endDate: String,
    commissionQuotaAmount: Number,
    commissionQuotaTitle: String,
    title: String,
    seriesTitle: String,
    height: [Number, String],
    amountField: {
      type: String as PropType<AmountField>,
      default: 'postedAmount' as AmountField,
    },
    dateField: {
      type: String as PropType<DateField>,
      default: 'postedDate' as DateField,
    },
  },
  data: () => ({
    data: [] as ReadonlyArray<ChartCumulativeAmountByDateItem>,
    additionalData: [] as ReadonlyArray<AdditionalDatum>,
    quotaData: [] as ReadonlyArray<QuotaData>,

    isFetching: 0,
    errors: [] as Error[],

    dialogQuery: undefined as Query | undefined,
  }),
  watch: {
    group: {
      deep: true,
      handler(value) {
        if (value === undefined) {
          this.errors.push(Error('quota data missing'))
        } else {
          this.fetchData()
        }
      },
    },
    additionalSeries: {
      immediate: true,
      deep: true,
      handler(item) {
        if (item === undefined) {
          this.additionalData = []
        } else {
          this.fetchGroupData(
            item.group,
            'additionalData',
            'commissionQuotaPeriodPostedDay'
          )
        }
      },
    },
  },
  computed: {
    dialog(): boolean {
      return !!this.dialogQuery
    },
    today(): string {
      const d = new Date()
      return (
        d.getFullYear() +
        '-' +
        padStart((d.getMonth() + 1).toString(), 2) +
        '-' +
        padStart(d.getDate().toString(), 2)
      )
    },
    calcEndDate(): string {
      return this.today < this.endDate ? this.today : this.endDate
    },
    startDateTimestamp(): number {
      return Date.parse(this.startDate)
    },
    endDateTimestamp(): number {
      return Date.parse(this.endDate)
    },
    quotaSeries(): ApexAxisChartSeries {
      return this.commissionQuotaAmount
        ? [
            {
              name: this.commissionQuotaTitle || `Quota`,
              data: [
                { x: this.startDate, y: 0 },
                ...this.quotaData
                  .slice()
                  .sort((a, b) => (a.endDate > b.endDate ? 1 : 0))
                  .map((z, i, arr) => ({
                    x: z.endDate,
                    y: arr
                      .slice(0, i + 1)
                      .reduce((prev, cur) => prev + (cur.amount || 0), 0),
                  })),
              ],
            },
          ]
        : [
            {
              name: 'No Quota',
              data: [],
            },
          ]
    },
    dataSeries(): ApexAxisChartSeries {
      return [
        ...this.getDataSeries(
          this.seriesTitle || 'Sales',
          this.data,
          datum => datum[this.dateField],
          datum => datum.cumulativeAmount,
          this.calcEndDate
        ),
      ]
    },
    additionalDataSeries(): ApexAxisChartSeries {
      return this.additionalSeries
        ? [
            ...this.getDataSeries<AdditionalDatum>(
              this.additionalSeries.name || 'Additional Series',
              this.additionalData,
              datum =>
                addDays(this.startDate, datum.commissionQuotaPeriodPostedDay),
              datum => datum.cumulativeAmount,
              this.endDate
            ),
          ]
        : []
    },
    series(): ApexAxisChartSeries {
      return [
        ...this.dataSeries,
        ...this.quotaSeries,
        ...this.additionalDataSeries,
      ]
    },
    chartOptions(): ApexOptions {
      return {
        chart: {
          type: 'line',
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: false,
          },
          height: this.height || 'auto',
          events: {
            click: (event, chartContext, options) => {
              if (options.seriesIndex === 0) {
                const { x } = options.config.series[options.seriesIndex].data[
                  options.dataPointIndex
                ]

                const fullGroup = {
                  ...this.group,
                  [this.dateField]: x,
                }

                this.dialogQuery = fullGroup
              }
            },
          },
        },
        colors: [
          this.$vuetify.theme.currentTheme.secondary,
          this.$vuetify.theme.currentTheme.primary,
          this.$vuetify.theme.currentTheme.accent,
        ],
        stroke: {
          curve: ['stepline', 'straight', 'stepline'],
          width: [3, 1, 2],
          dashArray: [0, 8, 3],
        },
        title: {
          text: this.title,
          align: 'left',
        },
        yaxis: {
          title: {
            text: `Posted Amount (${this.group.currency})`,
          },
          labels: {
            formatter: currencyFormatter(this.group.currency as string, {
              maximumFractionDigits: 0,
            }),
          },
        },
        xaxis: {
          type: 'datetime',
          min: this.startDateTimestamp,
          max: this.endDateTimestamp,
          title: {
            text: 'Posted Date',
          },
        },
        annotations: {
          xaxis: [
            ...(new Date(this.startDate).getTime() <= new Date().getTime() &&
            new Date(this.endDate).getTime() >= new Date().getTime()
              ? [
                  {
                    x: new Date().getTime(),
                    label: {
                      text: 'Today',
                      orientation: 'horizontal',
                      position: 'top',
                      offsetY: -14,
                    },
                  },
                ]
              : []),
          ],
        },
      }
    },
  },
  methods: {
    getDataSeries<
      T extends ChartCumulativeAmountByDateItem = ChartCumulativeAmountByDateItem
    >(
      title: string,
      data: readonly T[],
      xFn = (datum: T) => datum[this.dateField],
      yFn = (datum: T) => datum.cumulativeAmount,
      endDate: string
    ): ApexAxisChartSeries {
      return [
        {
          name: title,
          data: [
            ...(this.startDate &&
            data.length > 0 &&
            xFn(data[0]) !== this.startDate
              ? [{ x: this.startDate, y: 0 }]
              : []),
            ...data.map(d => ({
              x: xFn(d),
              y: yFn(d),
            })),
            ...(this.endDate &&
            data.length >= 1 &&
            xFn(data[data.length - 1]) < endDate
              ? [
                  {
                    x: endDate,
                    y: yFn(data[data.length - 1]),
                  },
                ]
              : []),
          ],
        },
      ]
    },
    fetchData() {
      this.fetchGroupData(this.group, 'data')
    },
    fetchGroupData(
      group: Group,
      propName: 'data' | 'additionalData',
      groupByField = 'postedDate'
    ) {
      this.errors = []

      this.isFetching++
      this.$api.charts
        .cumulativeAmountByDate(group, this.amountField, groupByField)
        .then(sales => {
          if (propName === 'data') this.data = deepFreeze(sales)
          if (propName === 'additionalData')
            this.additionalData = deepFreeze(sales)
        })
        .catch(err => {
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching--
        })

      if (propName === 'data') {
        this.isFetching++
        this.$api.charts.quotas
          .get(group)
          .then(quotas => {
            this.quotaData = deepFreeze(quotas)
          })
          .catch(err => {
            this.errors.push(err)
          })
          .finally(() => {
            this.isFetching--
          })
      }
    },
  },
})
